<template>
  <div>
    <b-modal
      id="modal-void-ticket-cancel-alert"
      :title="$t('reservation.voidTicket.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      size="lg"
      no-close-on-backdrop
    >

      <b-alert
        variant="warning"
        show
        class="p-1 py-50"
      >
        {{ $t('reservation.voidTicket.caution') }}
      </b-alert>

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>
      </template>
    </b-modal>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BModal, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BButton, BAlert,
  },
}
</script>

<style lang="scss" scoped>
</style>
